<template>
  <div class="card card-transaction mb-30 o-hidden">
    <div class="card-body p-0 bg-gray-100">
      <div class="card-transaction-header bg-white border-bottom-gray-200 position-relative o-hidden">
        <img alt="shield"
             class="custom-background"
             src="@/assets/images/briefcase.svg">
        <div class="flex-nowrap d-flex justify-content-between">
          <div class="mb-20">
            <div class="d-flex justify-content-between">
              <h4 class="mb-0">
                {{ transactionTitle }} </h4><br>
            </div>
            <b-badge
                class="mr-12"
                :variant="transactionStatus === 'Complete' ? 'success' : 'warning' "
                v-dompurify-html="transactionStatus"/>

            <b-badge
                v-if="transaction.coupon"
                class="mr-12"
                :variant="transactionStatus === 'Complete' ? 'success' : 'warning' "
            >
              Coupon {{ transaction.coupon.code }} Applied
            </b-badge>
          </div>
          <div class="text-right ml-auto cursor-pointer">
            <a @click="gotoTransaction"> <i class="text-25 text-secondary i-Next1"></i> </a>
          </div>
        </div>
      </div>
      <Products v-if="showProducts"
          :transaction="transaction"
          :transactionDetailName="transaction.coupon ? 'ClientTransactionCouponDetail' : 'ClientTransactionDetail'"/>
    </div>
  </div>
</template>

<script>
import {clientHelpers} from "@/mixins/clientHelpers";

import Products from "./Products";

export default {
  name: 'ClientTransactionCard',
  components: {Products},
  mixins: [clientHelpers],
  props: {
    transaction: {
      type: Object,
      required: true
    },
    showProducts: {
      type: Boolean,
      default: true
    },
    selectForFeedback: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    transactionTitle() {
      switch (this.transaction.type) {
        case 'estate_planning':
          return 'Estate Planning'
        case 'mortgage':
          return 'Mortgage'
        case 'insurance':
          return 'Insurance'
        default:
          return 'NO TRANSACTION TYPE'
      }

    },
    transactionStatus() {
      switch (this.transaction.status) {
        case 'in_progress':
          return 'In Progress'
        case 'in_progress_book':
          return 'Consultation Needed'
        case 'end_complete':
          return 'Complete'
        default:
          return ''
      }
    }
  },
  methods: {
    gotoTransaction() {
      if(this.selectForFeedback){
        this.$router.push({name: 'ClientTransactionFeedback', params: {transactionId: this.transaction.id}})
      }
      else if (this.transaction.coupon) {
        this.$router.push({name: 'ClientTransactionCouponDetail', params: {id: this.transaction.id}})
      }
      else{
        this.$router.push({name: 'ClientTransactionDetail', params: {id: this.transaction.id}})
      }
    }
  }
}
</script>
