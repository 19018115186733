<template>
  <b-col md="4">
    <div
        :class="[advisor.is_active ? 'user-active' : 'user-inactive']"
        class="user-card d-flex flex-column position-relative mt-30"
    >
      <h4 class="user-header position-relative">
        {{ advisor.name }}
        <div class="user-img">
          <img alt="" :src="image_thumb"/>
        </div>
      </h4>
      <div
          class="user-info-wrapper text-12 p-20 d-flex justify-content-center"
      >
        <div class="user-info">
          <template v-if="advisor.job_title">
            <div class="text-right font-weight-500 pr-2">Role:</div>
            <div>{{ advisor.job_title }}</div>
          </template>

          <template v-if="advisor.email">
            <div class="text-right font-weight-500 pr-2">Email:</div>
            <div>{{ advisor.email }}</div>
          </template>

          <template>
            <div class="text-right font-weight-500 pr-2">Active:</div>
            <div>{{ advisor.is_active ?  'Yes' : 'No' }}</div>
          </template>
        </div>
      </div>
    </div>
  </b-col>
</template>
<script>
export default {
  name: 'AdvisorCard',
  props: {
    advisor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
    image_thumb() {
      let thumb = require('@/assets/images/man-profile.svg');
      if (this.advisor.image_thumb) {
        thumb = this.advisor.image_thumb;
      } else if (this.advisor.image) {
        thumb = this.advisor.image;
      }
      return thumb;
    }
  }
};
</script>
