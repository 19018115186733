<template>
  <div class="card card-transaction mb-30 o-hidden">
    <div class="card-body p-0 bg-gray-100">
      <div class="card-transaction-header bg-white border-bottom-gray-200 position-relative o-hidden">
        <img alt="shield"
             class="custom-background"
             src="@/assets/images/briefcase.svg">
        <div class="flex-nowrap d-flex justify-content-between">
          <div class="mb-20">
            <div class="d-flex justify-content-between">
              <h4 class="mb-0">
                {{ referral.name }} </h4><br>
            </div>
            <b-badge
                class="mr-12"
                variant="Complete"
                v-dompurify-html="interest_type"/>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReferralCard',
  props: {
    referral: {
      type: Object,
      required: true
    },
  },
  computed:{
    interest_type(){
      switch (this.referral.interest_type) {
        case 'estate_planning':
          return 'Estate Planning'
        case 'estate_planning_free':
          return 'Estate Planning (Free Will)'
        case 'mortgage':
          return 'Mortgage'
        case 'insurance':
          return 'Insurance'
        default:
          return 'NOT RATED'
      }
    },
  },
}
</script>
