<template>
  <div class="pb-48">
    <div class="section-title">
      <h4 class="p-3 mb-2">
        <span>Your Vault</span>
      </h4>
      <a class="d-block" @click="goto">
        <i class="i-Gear cursor-pointer text-25 text-secondary"
        ></i>
      </a>
    </div>
    <b-alert
        class="mb-48 p-3"
        show
        variant="alert alert-card alert-warning text-center"
    >The vault is an encrypted space within our client portal where you can upload documents, videos, passwords and
      other personal information. If you pass away or become il the information can then be made available to specific
      people.
    </b-alert>
    <b-row>
      <b-col v-if="vault && vault.form.vault_items" cols="12">
        <VaultItemCard
            v-for="(item, index) in vault.form.vault_items"
            class="mt-2"
            :key="index"
            :item="item"
            @deleteItem="deleteItem"
            @editItem="editItem"
        />
      </b-col>
      <b-col cols="12" v-if="vault && vault.form.data">
        <div
            class="add-item full-height mb-30 d-flex"
            @click="gotoItem(null)"
        >
          <h3>
            <i class="i-Add text-40"
               style="vertical-align: middle"
            ></i
            >
            Add Vault Item
          </h3>
        </div>
      </b-col>
    </b-row>

    <b-button v-if="vault && !vault.form.data" class="mt-24" variant="secondary"
              @click="goto">
      Setup Your Vault
    </b-button>

  </div>
</template>

<script>
// import {http} from "@/services";
import {httpQuestionnaire} from "@/services";

import VaultItemCard from "@/views/client/dashboard/vault/VaultItemCard";

export default {
  name: 'Vault',
  components: {
    VaultItemCard,
  },
  mounted() {
    this.getVault()
  },
  data() {
    return {
      selectedItem: null,
      vault: null,
    }
  },
  methods: {
    goto() {
      this.$router.push({
        name: "ClientVaultInstructions",
        params: {
          clientId: this.vault.client.id,
        },
      }).catch(
          error => {
            console.log(error)
          }
      )
    },
    gotoItem(itemId = null) {
      let params = {
        clientId: this.vault.client.id,
        vaultId: this.vault.form.id,
      };
      if (itemId) {
        params.vaultItemId = itemId
        this.toVaultItem(params)
      } else {
        this.addItem(params)
      }

    },
    toVaultItem(params) {
      this.$router.push({
        name: "ClientVaultItem",
        params: params,
      }).catch(
          error => {
            console.log(error)
          }
      )
    },
    addItem(params) {
      return httpQuestionnaire
          .get(`vault_item?client_id=${this.vault.client.id}&vault_id=${this.vault.form.id}`)
          .then((response) => {
            console.log('item created!', response.data);
            params.vaultItemId = response.data.form.id;
            this.toVaultItem(params);
          })
          .catch((error) => {
            console.log(error);
          });

    },
    editItem(item) {
      this.gotoItem(item.id)
    },
    deleteItem(item) {
      return httpQuestionnaire
          .delete('vault_item', {params: {id: item.id}})
          .then(() => {
            this.getVault();
          })
          .catch((error) => {
            console.log(error);
          });

    },
    getVault() {
      httpQuestionnaire.get('vault').then(
          response => {
            this.vault = response.data
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    },
  }
}
</script>
