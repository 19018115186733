<template>
  <div>
    <Hero v-if="user"
        :userName="user.name"
    />
    <Header/>

    <b-container>
      <b-row>
        <b-col v-show="false">
          <div class="section-title">
            <h4 class="p-3 mb-2">
              <span>Quick Links</span>
            </h4>
          </div>
          <p>Under Construction</p>
          <div class="accordion sticky-top" role="tablist" style="display: none">
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <b-button style="border-bottom: 2px solid #FFFFFF18; border-radius: 0 !important;" block
                          v-b-toggle.accordion-1 variant="secondary">Update your Will
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>I start opened because <code>visible</code> is <code>true</code></b-card-text>
                  <b-card-text> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                    richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
                    brunch. Food truck quinoa nesciunt laborum eiusmod.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <b-button style="border-bottom: 2px solid #FFFFFF18; border-radius: 0 !important;" block
                          v-b-toggle.accordion-1 variant="secondary">Download your Will
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>I start opened because <code>visible</code> is <code>true</code></b-card-text>
                  <b-card-text> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                    richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
                    brunch. Food truck quinoa nesciunt laborum eiusmod.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <b-button style="border-bottom: 2px solid #FFFFFF18; border-radius: 0 !important;" block
                          v-b-toggle.accordion-1 variant="secondary">Update your Will Appendix
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>I start opened because <code>visible</code> is <code>true</code></b-card-text>
                  <b-card-text> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                    richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
                    brunch. Food truck quinoa nesciunt laborum eiusmod.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <b-button style="border-bottom: 2px solid #FFFFFF18; border-radius: 0 !important;" block
                          v-b-toggle.accordion-2 variant="secondary">Move your appointment
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                    richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
                    brunch. Food truck quinoa nesciunt laborum eiusmod.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <b-button style="border-bottom: 2px solid #FFFFFF18; border-radius: 0 !important;" block
                          v-b-toggle.accordion-2 variant="secondary">Book an appointment
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                    richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
                    brunch. Food truck quinoa nesciunt laborum eiusmod.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <b-button style="border-bottom: 2px solid #FFFFFF18; border-radius: 0 !important;" block
                          v-b-toggle.accordion-3 variant="secondary">Make your Lasting Power of Attorney
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                    richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
                    brunch. Food truck quinoa nesciunt laborum eiusmod.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <b-button style="border-bottom: 2px solid #FFFFFF18; border-radius: 0 !important;" block
                          v-b-toggle.accordion-3 variant="secondary">Ask a question
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                    richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
                    brunch. Food truck quinoa nesciunt laborum eiusmod.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <b-button style="border-bottom: 2px solid #FFFFFF18; border-radius: 0 !important;" block
                          v-b-toggle.accordion-3 variant="secondary">Review your Mortgage
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                    richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
                    brunch. Food truck quinoa nesciunt laborum eiusmod.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <b-button style="border-bottom: 2px solid #FFFFFF18; border-radius: 0 !important;" block
                          v-b-toggle.accordion-3 variant="secondary">Review your Insurance
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                    richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
                    brunch. Food truck quinoa nesciunt laborum eiusmod.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <b-button style="border-bottom: 2px solid #FFFFFF18; border-radius: 0 !important;" block
                          v-b-toggle.accordion-3 variant="secondary">Download Documents
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                    richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
                    brunch. Food truck quinoa nesciunt laborum eiusmod.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <b-button style="border-bottom: 2px solid #FFFFFF18; border-radius: 0 !important;" block
                          v-b-toggle.accordion-3 variant="secondary">Upload to your Vault
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                    richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
                    brunch. Food truck quinoa nesciunt laborum eiusmod.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <b-button style="border-bottom: 2px solid #FFFFFF18; border-radius: 0 !important;" block
                          v-b-toggle.accordion-3 variant="secondary">Refer a friend
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                    richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
                    brunch. Food truck quinoa nesciunt laborum eiusmod.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <b-button style="border-bottom: 2px solid #FFFFFF18; border-radius: 0 !important;" block
                          v-b-toggle.accordion-3 variant="secondary">Provide feedback
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                    richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
                    brunch. Food truck quinoa nesciunt laborum eiusmod.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <b-button style="border-bottom: 2px solid #FFFFFF18; border-radius: 0 !important;" block
                          v-b-toggle.accordion-3 variant="secondary">Make your Lasting Power of Attorney
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                    richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
                    brunch. Food truck quinoa nesciunt laborum eiusmod.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>

        </b-col>
        <b-col md="7" offset-md="1">
          <div class="section-title">
            <h4 class="p-3 mb-2">
              <span>Your Profile</span>
            </h4>
          </div>
          <b-alert
              class="mb-48 p-3"
              show
              variant="alert alert-card alert-success text-center"
          >The profile contains all the personal information we hold about you. By clicking on the
            profile it is possible to add, remove and update your personal details.
          </b-alert>
          <b-row>
            <b-col cols="12">
              <ClientProfile
                  v-if="user && user.is_client"
                  :client="user.is_client"/>
            </b-col>
          </b-row>

          <Appointments
              ref="appointments"
              :numbered="false"
              :showBookingButton="true"
              appointment-type="General Consultation"
              host-role="Estate Planning Introducer"
              @refresh="$emit('refreshTransaction', $event)"
          />

          <RecentTransactions />

          <Feedbacks v-if="runningLocal" />
          <Referrals v-if="runningLocal" />
          <Documents v-if="runningLocal" />
          <Products v-if="runningLocal" />
          <Vault/>

        </b-col>
      </b-row>
    </b-container>

    <Advisors/>

    <Contact/>

    <b-container>
      <Footer />
    </b-container>
  </div>
</template>

<script>
import {toast} from '@/mixins/toast'

import RecentTransactions from "./RecentTransactions";
import Contact from "../../../components/common/contact/Contact";
import Header from "./Header";
import ClientProfile from "./ClientProfile";
import Footer from "@/components/common/questionnaires/questionnaire/Footer";
import Appointments from "@/components/common/appointment";
import Advisors from "@/views/client/dashboard/Advisors";
import Vault from "@/views/client/dashboard/Vault";
import Hero from "@/views/client/dashboard/Hero";

import Feedbacks from "./Feedbacks";
import Referrals from "./Referrals";
import Documents from "./Documents";
import Products from "./Products";

export default {
  name: 'Dashboard',
  mixins: [toast],
  components: {
    Hero,
    Advisors,
    Appointments,
    Footer,
    ClientProfile,
    Header,
    Contact,
    RecentTransactions,
    Vault,
    Feedbacks,
    Referrals,
    Documents,
    Products,
  },
  mounted() {
    this.pageLoadToastMessage()
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    runningLocal() {
      return process.env.NODE_ENV !== 'production'
    }
  },
}
</script>
