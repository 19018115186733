<template>
  <b-row class="pl-12 pr-12 pt-12 pb-12">
    <b-col v-for="(product, index) in products"
           :key="index"
           cols="12">
      <ProductCard
          :product="product"
          :transaction-id="transaction.id"
          :transactionDetailName="transactionDetailName"
      />
    </b-col>
  </b-row>
</template>
<script>
import ProductCard from "./ProductCard";

export default {
  name: 'Products',
  components: {ProductCard},
  props: {
    transaction: {
      type: Object,
      required: true
    },
    transactionDetailName: {
      type: String,
      required: false
    }
  },
  computed: {
    products() {
      let wills = [];
      if (this.transaction.wills) {
        wills = this.transaction.wills.map((product) => {
          return {
            type: 'will',
            label: product.mirror_will ? 'Last Will & Testament (Mirror)' : 'Last Will & Testament',
            data: product
          };
        });
      }
      let lpas = [];
      if (this.transaction.lpas) {
        lpas = this.transaction.lpas.map((product) => {
          return {
            type: 'lpa',
            label: product.mirror_lpa ? 'Lasting Power of Attorney (Mirror)' : 'Lasting Power of Attorney',
            data: product
          };
        });
      }
      let policies = [];
      if (this.transaction.policies) {
        policies = this.transaction.policies.map((product) => {
          if (product.policy_recommended) {
            return {
              type: 'policy',
              label: `${product.policy_recommended.type}`,
              subLabel: `${product.policy_recommended.provider}`,
              data: product
            };
          } else return {
            type: 'policy',
            label: `Unknown`,
            subLabel: `Unknown`,
            data: product
          };
        });
      }
      let storages = [];
      if (this.transaction.storages && this.transaction.type === 'estate_planning') {
        storages = this.transaction.storages.map((product) => {
          return {
            type: 'storage',
            label: `Will Updates & Storage`,
            subLabel: null,
            data: product
          };
        });
      }
      let severances = [];
      if (this.transaction.severances) {
        severances = this.transaction.severances.map((product) => {
          return {
            type: 'severance',
            label: 'Severance',
            data: product
          };
        });
      }
      let expressions = [];
      if (this.transaction.expressions) {
        expressions = this.transaction.expressions.map((product) => {
          return {
            type: 'expression',
            label: product.mirror_expression ? 'Expression of Wishes (Mirror)' : 'Expression of Wishes',
            data: product
          };
        });
      }
      let mortgages = [];
      if (this.transaction.mortgages && this.transaction.type === 'mortgage') {
        mortgages = this.transaction.mortgages.map((product) => {
          return {
            type: 'mortgage',
            label: `Application - ${product?.mortgage_recommended?.lender}`,
            subLabel: null,
            data: product
          };
        });
      }
      let result = [
        ...wills,
        ...lpas,
        ...policies,
        ...storages,
        ...severances,
        ...expressions,
        ...mortgages
      ];
      if (!result.length) {
        let reviews = [];
        if (this.transaction.estate_planning_surveys.length && this.transaction.type === 'estate_planning') {
          reviews.push(
              {
                type: 'review',
                label: 'Estate Planning Review',
                data: {
                  ...this.transaction,
                  clients: this.transaction.clients
                }
              }
          )
        }

        if (!this.transaction.mortgages.length && this.transaction.type === 'mortgage') {
          reviews.push({
            type: 'review',
            label: 'Mortgage Review',
            data: {
              ...this.transaction,
              clients: this.transaction.clients
            }
          })
        }

        if (!this.transaction.policies.length && this.transaction.type === 'insurance') {
          reviews.push({
            type: 'review',
            label: 'Insurance Review',
            data: {
              ...this.transaction,
              clients: this.transaction.clients
            }
          })
        }

        result = [
          ...result,
          ...reviews
        ]
      }
      return result
    }
  }
}
</script>
