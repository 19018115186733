<template>
  <div class="bg-white border-bottom-gray-200 pt-12 pb-12 hero-sub-bar mb-48">
    <div class="container">
      <b-row>
        <b-col md="4">
          <div class="d-flex align-items-center pt-2 pb-2">
            <div class="rounded-lg text-white bg-secondary d-flex flex-column align-items-center justify-content-center p-12">
              <i class="i-Management text-25"></i></div>
            <div class="d-flex flex-column pl-16">
              <div class="text-16 font-weight-500">Track Progress</div>
              <div class="">Live updates on your case progress.</div>
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="d-flex align-items-center pt-2 pb-2">
            <div class="rounded-lg text-white bg-secondary d-flex flex-column align-items-center justify-content-center p-12">
              <i class="i-Management text-25"></i></div>
            <div class="d-flex flex-column pl-16">
              <div class="text-16 font-weight-500">Expert Support</div>
              <div class="">Questions answered same day.</div>
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="d-flex align-items-center pt-2 pb-2">
            <div class="rounded-lg text-white bg-secondary d-flex flex-column align-items-center justify-content-center p-12">
              <i class="i-Data-Security text-25"></i></div>
            <div class="d-flex flex-column pl-16">
              <div class="text-16 font-weight-500">Safe & Secure</div>
              <div class="">All information is fully protected.</div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>