<template>
  <div
      class="card-transaction-product-outer card-style"
  >
    <router-link
        :to="{name: transactionDetailName, params: { id: transactionId }}"
        class="card-transaction-product"
    >
      <div
          class="card-transaction-product-header align-item-center flex-nowrap"
      >
        <div>
          <h5 class="mb-1">
            {{ product.label }}
          </h5>

          <small v-if="product.data.client" class="client-names">
            {{ clientNameShort(product.data.client) }}
          </small>
          <template v-else-if="product.data.clients">
            <small
                v-for="(client, index) in product.data.clients"
                :key="index"
                class="client-names"
            >
              {{ clientNameShort(client) }}
            </small>
          </template>
          <small class="text-muted ml-8"
          >Created:
            <span class="font-weight-500">{{
                formatDate(product.data.created_date)
              }}</span>
          </small>

        </div>
      </div>

    </router-link>
  </div>
</template>

<script>
import {dateFormat} from '@/mixins/dateFormat';
import {clientHelpers} from '@/mixins/clientHelpers';

export default {
  name: 'ProductCard',
  props: {
    product: {
      type: Object,
      required: true
    },
    transactionId: {
      type: Number,
      required: true
    },
    transactionDetailName: {
      type: String,
      default: 'ClientTransactionDetail'
    }
  },
  mixins: [dateFormat, clientHelpers],
  computed: {
    getProductUrl() {
      let url = '';
      if (this.product.type === 'will') url = 'will';
      else if (this.product.type === 'lpa') url = 'lpa';
      else if (this.product.type === 'policy') url = 'policy';
      else if (this.product.type === 'storage') url = 'storage';
      else if (this.product.type === 'severance') url = 'severance';
      else if (this.product.type === 'expression') url = 'expression';
      return url;
    }
  }
};
</script>
