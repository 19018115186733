<template>
  <div>
      <div class="section-title">
        <h4 class="p-3 mb-2">
          <span>Your Referrals</span>
        </h4>
      </div>

      <template v-if="referrals.length">
        <ReferralCard v-for="(referral, index) in referrals" :key="index" 
        :referral="referral"
       />
      </template>

      <b-button variant="secondary" size="lg" class="w-100" @click.stop="goto">Add a referral</b-button>

  </div>
</template>

<script>
import ReferralCard from "./referral/ReferralCard";

import {http} from "@/services";


export default {
  name: 'Referrals',
  components:{
    ReferralCard,
  },
  data() {
    return {
      show:false,
      referrals :[],
    }
  },
  mounted(){
    this.fetchReferrals();    
  },
  methods:{
    fetchReferrals() {
      http.get('client_referrals').then(
          response => {
            this.referrals = response.data
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    },
    goto() {
      this.$router.push({
        name: 'ClientReferral'
      }).catch(
          error => {
            console.log(error)
          }
      )

    }


  },
}
</script>
