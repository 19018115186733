<template>
  <div>
      <div class="section-title">
        <h4 class="p-3 mb-2">
          <span>Your Products</span>
        </h4>
      </div>
      <!-- <b-alert
          class="mb-48 p-3"
          show
          variant="alert alert-card alert-warning text-center"
      >Some explanation about feedbacks?
      </b-alert> -->
      <!-- <b-card-group columns style="column-count: 2;">
        <div v-for="(transaction, index) in recent" :key="`transaction${index}`">
          <TransactionCard
              :transaction="transaction"
              :show-products="true"
          />
        </div>
      </b-card-group> -->
  </div>
</template>

<script>


export default {
  name: 'Products',
  mounted() {
    // this.fetchRecent()
  },
  data() {
    return {
      recent: []
    }
  },
  methods: {
    // fetchRecent() {
    //   http.get('client_transaction_recent_list').then(
    //       response => {
    //         this.recent = response.data
    //       }
    //   ).catch(
    //       error => {
    //         console.log(error)
    //       }
    //   )
    // }
  }
}
</script>
