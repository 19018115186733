<template>
  <div>
      <div class="section-title">
        <h4 class="p-3 mb-2">
          <span>Your Feedbacks</span>
        </h4>
      </div>

      <template v-if="feedbackedTransactions.length">
        <FeedbackCard v-for="(transaction, index) in feedbackedTransactions" :key="index" 
        :transaction="transaction"
       />
      </template>

      <b-button v-if="noFeedbackedTransactions.length" variant="secondary" size="lg" class="w-100" @click="show=true">Give a feedback</b-button>

      <FeedbackModal 
        v-model="show"
        title="Give a feedback"
        :sub-title="'Please select a transaction to give feedback'"
        :transactions="noFeedbackedTransactions"
       />

  </div>
</template>

<script>
import FeedbackModal from "./feedback/FeedbackModal";
import FeedbackCard from "./feedback/FeedbackCard";

import {http} from "@/services";


export default {
  name: 'Feedbacks',
  components:{
    FeedbackModal,
    FeedbackCard,
  },
  data() {
    return {
      show:false,
      transactions :[],
    }
  },
  mounted(){
    this.fetchTransactions();    
  },
  computed:{
    noFeedbackedTransactions(){
      if(this.transactions){
        return this.transactions.filter(t=> !t.feedback)
      }
      return []
    },
    feedbackedTransactions(){
      if(this.transactions){
        return this.transactions.filter(t=> t.feedback)
      }
      return []
    },
  },
  methods:{
    fetchTransactions() {
      http.get('client_transaction_recent_list').then(
          response => {
            this.transactions = response.data
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    }
  },
}
</script>
