<template>
  <b-card body-class="p-0"
          class="hover-down trans-card"
          @click="goto">
    <div class="d-flex justify-content-between align-items-center p-3">
      <div class="d-flex align-items-center">
        <div class="img-wrapper pr-3">
          <img :src="image"
               alt="cardImage">
        </div>
        <div class="d-flex align-items-start flex-column">
          <h5 class="">{{ clientNameFirstLast(client) }}</h5>
          <h6 class="mb-0 text-mute">Profile</h6>
        </div>
      </div>
      <div class="cursor-pointer pr-2"
           @click.stop="goto"><i class="text-25 text-secondary i-Next1"></i></div>
    </div>
    <div class="bg-gray-100 border-top border-gray-200">
      <b-progress-bar :value="40" variant="primary"></b-progress-bar>
    </div>
    <div class="d-flex align-items-center justify-content-start pl-3 pr-1 pt-12 pb-12"></div>
  </b-card>
</template>

<script>
import {clientHelpers} from "@/mixins/clientHelpers";

export default {
  name: 'ClientProfile',
  mixins: [clientHelpers],
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  computed: {
    image() {
      // image for card
      if (this.client.profileSex === 'male') {
        return require('@/assets/images/man-profile.svg')
      }
      return require('@/assets/images/woman-profile.svg')
    }
  },
  methods: {
    goto() {
      this.$router.push({
        name: 'ClientProfileFull',
        params: {clientId: this.client.id}
      }).catch(
          error => {
            console.log(error)
          }
      )

    }
  }
}
</script>
