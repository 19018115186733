<template>
  <div class="border-bottom-gray-200 border-top-gray-200 pt-40 pb-40 bg-white mt-48 mb-100">
    <b-container>
          <div class="border-extra-wide-left border-secondary pl-40"><h4 class="mb-30">Your Advisors</h4>
            <p class="mb-20">Contact details are displayed for advisors who you have had an appointment with (or have an appointment scheduled for). The best way to get in touch is by email or by using the form at the bottom of this page. Alternatively please contact our support team on 01785 336222 or enquiries@dunham-mccarthy.co.uk.</p>
            <b-row v-if="advisors">
              <AdvisorCard
                  v-for="(advisor, index) in advisors"
                  :key="index"
                  :advisor="advisor"/>
            </b-row>
          </div>
    </b-container>
  </div>
</template>

<script>
import {http} from "@/services";

import AdvisorCard from "@/views/introducer/dashboard/AdvisorCard";

export default {
  name: 'Advisors',
  components: {
    AdvisorCard
  },
  mounted() {
    this.getAdvisors()
  },
  data() {
    return {
      advisors: null
    }
  },
  methods: {
    getAdvisors() {
      http.get('client_advisors').then(
          response => {
            this.advisors = response.data
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    }
  }
}
</script>
