<template>
  <b-modal v-model="showModal"
           :hide-header="true"
           modal-class="modal-style-one"
           scrollable
           size="lg"
           >

           <div class="questionnaire">

            <QuestionBase :disabledValid="true"
                    :errors="[]"
                    :question="question"
                    :sub-section="true"
                    :valid="true" />

          </div>

          <b-card-group columns style="column-count: 2;" v-if="transactions">
            <div v-for="(transaction, index) in transactions" :key="`transaction${index}`">
              <TransactionCard
                  :transaction="transaction"
                  :show-products="false"
                  :select-for-feedback="true"
              />
            </div>

          </b-card-group>

           <template #modal-footer="{ cancel }">
      <div class="w-100 m-0">
        <button class="btn btn-backwards white-border w-100"
                @click="cancel">
          Cancel
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import TransactionCard from "@/views/client/dashboard/transaction/TransactionCard.vue";

export default {
  name: "FeedbackModal",
  components:{
    QuestionBase,
    TransactionCard,
  },
  props: {
    value: {
      // show modal
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
    },
    transactions: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  computed: {
    showModal: {
      // show modal
      set(value) {
        this.$emit("input",
            value);
      },
      get() {
        return this.value;
      },
    },
    question() {
      return {
        title: this.title,
        subTitle: this.subTitle,
        tip: null,
      };
    },
  },
};
</script>
