<template>
  <div v-if="recent.length">
      <div class="section-title">
        <h4 class="p-3 mb-2">
          <span>Your Transactions</span>
        </h4>
      </div>
      <b-alert
          class="mb-48 p-3"
          show
          variant="alert alert-card alert-warning text-center"
      >If you are in the process of taking advice in a particular area (taking out a mortgage or making your Lasting Power of Attorney for example) details will appear below, click on the relevant transaction for more details.
      </b-alert>
      <b-card-group columns style="column-count: 2;">
        <div v-for="(transaction, index) in recent" :key="`transaction${index}`">
          <TransactionCard
              :transaction="transaction"
              :show-products="true"
          />
        </div>
      </b-card-group>
  </div>
</template>

<script>
import {http} from "../../../services";

import TransactionCard from "./transaction/TransactionCard";

export default {
  name: 'RecentTransactions',
  components: {TransactionCard},
  mounted() {
    this.fetchRecent()
  },
  data() {
    return {
      recent: []
    }
  },
  methods: {
    fetchRecent() {
      http.get('client_transaction_recent_list').then(
          response => {
            this.recent = response.data
          }
      ).catch(
          error => {
            console.log(error)
          }
      )
    }
  }
}
</script>
