<template>
  <div class="hero-wrapper">
    <div class="hero trans-hero">
      <img
          alt="hero"
          class="trans-background"
          style="filter: invert()"
          src="@/assets/images/Welcome.png"
      />
      <div class="container">
        <h2 class="ml-2 text-white mb-3">Welcome {{ userName }}</h2>
        <h1 class="ml-2 text-white mb-0">Secure Client Portal</h1>
        <!--          <h3 class="text-white">Comprehensive, Quality Advice.</h3>-->
        <div class="hero-inline hero-animated-pretext">Featuring </div>
        <div class="hero-animation-container">
          <div class="hero-animated-items">
            <div class="hero-animated-item">Live Updates</div>
            <div class="hero-animated-item">Expert Support</div>
            <div class="hero-animated-item">Realtime Tracking</div>
            <div class="hero-animated-item">Secure Communication</div>
          </div>
          <div class="bounding">
            <div class="hero-ghost-text">
              Live Updates
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  mounted() {
    this.interval = setInterval(() => {
      this.heroPush();
      setTimeout(this.heroPop, 600)
    }, 3000)
  },
  data() {
    return {
      interval: null
    }
  },
  destroyed() {
    clearInterval(this.interval)
  },
  props: {
    userName: {
      type: String,
      required: false
    }
  },
  methods: {
    heroPush() {
      let ele = document.querySelector('.hero-animated-items');
      let slot = document.querySelector('.hero-ghost-text');

      let left = parseInt(ele.style.left.split?.('px')[0]) || 0;
      let gap = parseInt(getComputedStyle(ele).gap.split?.("px")[0]) || 0;
      let width = ele.firstElementChild.offsetWidth + gap;
      left -= width;

      slot.innerText = "&nbsp";
      slot.style.width = this.getContentWidth(ele.querySelector(':nth-child(2)')) + "px";

      document.querySelector('.hero-animated-items').style.left = `${left}px`;
    },
    heroPop() {
      let ele = document.querySelector('.hero-animated-items');
      let child = ele.firstElementChild;
      child.remove();

      let transition = ele.style.transition;
      ele.style.transition = "none";
      ele.style.left = 0;

      setTimeout(() => {
        ele.style.transition = transition;
      }, 600);

      ele.appendChild(child);
    },
    getContentWidth(element) {
      let styles = getComputedStyle(element)

      return element.clientWidth
          - parseFloat(styles.paddingLeft)
          - parseFloat(styles.paddingRight)
          +10
    }
  }
};
</script>