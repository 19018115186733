<template>
  <b-card body-class="p-0" class="overflow-hidden mb-30">
    <div class="d-flex justify-content-between align-items-center p-20 mb-20">

      <div class="d-flex align-items-center align-items-center">
        <b-link class="d-flex" v-if="item.type==='Password'" target="_blank" :href="url">
          <i :class="iconClass" class="mb-2 text-25 text-primary mr-2"></i>
          <h5 class="">{{ item.title }}</h5>
        </b-link>
        <b-link class="d-flex" v-if="item.type==='File'" target="_blank" :href="item.file">
          <i :class="iconClass" class="mb-2 text-25 text-primary mr-2"></i>
          <h5>{{ item.title }}</h5>
        </b-link>
        <b-link class="d-flex" v-if="item.type==='Video'" target="_blank" :href="item.file">
          <i :class="iconClass" class="mb-2 text-25 text-primary mr-2"></i>
          <h5>{{ item.title }}</h5>
        </b-link>
      </div>

      <div>
        <b-link @click="$emit('editItem', item)" class="mr-3" title="Edit">
          <i class="i-Pen-4 text-25 text-success"></i>
        </b-link>
        <b-link @click="confirmDelete" title="Delete">
          <i class="i-File-Trash text-25 text-danger"></i>
        </b-link>
      </div>
    </div>

    <b-card-footer class="bg-primary text-white">
      <div v-if="item.data.settings.share!=='Do not share'">
        Will be shared with
        <strong>{{ item.data.settings.people.map(p => p.name_first).join(', ') }}</strong>
      </div>
      <div v-else>
        <strong>{{ item.data.settings.share }}</strong>
      </div>


    </b-card-footer>
  </b-card>
</template>
<script>
export default {
  name: 'VaultItemCard',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    url() {
      if (this.item.web_address.indexOf('http://') == -1 && this.item.web_address.indexOf('https://') == -1) {
        return 'http://' + this.item.web_address;
      }
      return this.item.web_address;
    },
    iconClass() {
      if (this.item.type === 'Password') {
        return "i-Internet"
      } else if (this.item.type === 'Video') {
        return "i-Video-4"
      } else {
        return "i-File-Cloud"
      }
    },
  },
  data() {
    return {};
  },
  methods: {
    confirmDelete() {
      this.$bvModal.msgBoxConfirm('Are you sure that want to delete this item?',
          {
            title: 'Please Confirm',
            size: 'sm',
            buttonSize: 'sm',
            cancelVariant: 'outline-primary',
            okVariant: 'secondary',
            okTitle: 'Confirm',
            cancelTitle: 'Cancel',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then((value) => {
        if (value) {
          this.$emit('deleteItem', this.item);
        }
      }).catch(() => {
        // An error occurred
      });

    },
  }
};
</script>
